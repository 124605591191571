.img-logo {
    width: 100px;
    height: 100px;
}
.logo-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 30px;
    flex-direction: column;
}
.title-header {
    font-size: 1.3rem;
    color: rgb(255, 255, 255);
    font-weight: 800;
    padding-left: 20px;
}
.header-padding {
    padding: 0;
    height: 80px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.user-name-header {
    color: white;
    font-size: 1.1rem;
    text-align: end;
    padding-right: 20px;
}
.txt-hello {
    color: white;
    font-size: 1rem;
}
.ant-btn, .ant-radio-group .ant-radio-button-wrapper {
    line-height: normal;
}